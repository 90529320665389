import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addFile, retrieveData, storeData } from "../../services/Storage";
import { useNavigate, useParams } from "react-router-dom";

import UiInputText from "../../components/inputs/UiInputText";
import UiIconMenu from "../../components/icons/icon-category";

import UiDropDown from "../../components/inputs/UiDropDown";
import UiSearchDropDown from "../../components/inputs/UiSearchDropDown";
import UiSwitch from "../../components/switch/UiSwitch";
import UiExpandebleList from "../../components/inputs/UiExpandebleList";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";
import UiButtonColor from "../../components/button/UiButtonColor";

import {
  getMenuList as getMenuListAction,
  update as updateAction
} from "../../redux/modules/menu";


import { getCategoryList as getCategoryListAction } from "../../redux/modules/category";
import { getTagsList as getTagsListAction, update as updateTagAction } from "../../redux/modules/tags";
import { getFilterParams as getFilterParamsAction } from "../../redux/modules/storage";

import {
  inArrayValueByKey,
  toArrayLabelListKey,
  unCheckInArray,
} from "../../utilities/array";

import "./MenuStyle.scss";
import Filter from "../../constants/Filter";


function MenuEditPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [menu, setMenu] = useState({
    isEdit: false,
    title: "",
    full_title: "",
    description: "",
    category: 0,
    is_menu: 1,
    is_site_menu: 0,

    root_category_id: 0,
    description: "",
  });
  const tableTitles = [
    { sort: false, title: "" },
    { sort: true, title: "ID" },
    { sort: true, title: "Имя" },
    { sort: false, title: "Фильтр" },
    { sort: false, title: "Удалить" },
  ];

  const [filterValues, setFilterValues] = useState([]);
  const [categoryValue, setCategoryValue] = useState(null);

  const [categoriesList, setCategoriesList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [tagsList, setTagsList] = useState([]);

  const imgArr = Array(96).fill(0);

  useEffect(() => {
    props.getCategoryList().then((data) => {
      data.unshift({ id: null, title: "" });
      setCategoriesList(data);
    });
    props.getFilterParams().then((data) => {
      let _data = data.filter((item) => Filter.ALLOEWED_FILTER_ARRAY.includes(item.key));
      console.log(_data)
      let priceArr = [];
      for (let i = 0; i <= 40; i++) priceArr.push(i * 500);

      _data.unshift({
        key: "max_price",
        title: "Цена до",
        value: priceArr
      })
      _data.unshift({
        key: "min_price",
        title: "Цена от",
        value: priceArr
      })

      setFilterList(_data);
    });

    props.getMenuList().then((data) => {
      if (data) {
        let menuList = data;
        menuList.unshift({
          value: null,
          text: " -- ",
        });
        setMenuList(menuList);
      }
    });

  }, []);

  useEffect(() => {
    getTags(id)
  }, [id]);


  useEffect(() => {
    let editData = retrieveData("editMenu");
    if (editData) {
      console.log(editData);
      setMenu(editData);

      if (editData.category_filter?.includes("category=")) {
        let _split = editData.category_filter.split("category=");
        setCategoryValue(_split[1].split('&')[0]);
      }

      convertFromFilterValue(editData.category_filter);
    }



    window?.scrollTo(0, 0);
  }, [id]);

  function getTags(id) {
    props.getTagsList(id).then((data) => {
      let arr = [];
      data.map((item) => {
        arr.push([
          {
            button: "edit", onClick: () => {
              storeData("editMenuTag", item);
              navigate(`/edit/menu/tag/${id}`);
            }
          },
          {
            text: item.id,
          },
          {
            text: item.title,
          },
          {
            text: item.category_filter,
          },
          {
            button: item.show ? "eye-open" : "eye",
            color: "red",
            onClick: () => {
              if (window.confirm("Удалить элемент ?")) {
                let _item = item;
                _item.is_del = 1;
                props.updateTagAction(_item).then(() => getTags(id))
              }

              ;
            },
          },
        ]);
      })
      setTagsList(arr);
    });
  }

  /**
   *
   * @param {string} key
   * @param {any} val
   */
  function updateOrderObj(key, val) {
    let _obj = menu;
    _obj[key] = val;
    setMenu({ ..._obj });
  }

  /**
   *
   * @returns String
   */
  function convertFilterValue() {
    let val = [];
    filterValues.map((item) => {
      if (item.value.length > 0) val.push(`${item.key}=${item.value.join()}`);
    });
    return val.join("&");
  }

  /**
   * @param {String} _list
   * @returns Array
   */
  function convertFromFilterValue(_list) {
    let resultArr = [];
    if (_list) {
      let arr = _list.split("&");
      arr.map((item) => {
        let _item = item.split("=");
        if (_item.length > 0) {
          if (_item[1].length > 0) {
            resultArr.push({
              key: _item[0],
              value: _item[1].split(","),
            });
          }

        }
      });
    }
    setFilterValues(resultArr);
  }

  /**
   *
   * @param {string} key
   * @param {any} val
   * @param {boolean} replace
   */
  function updateFilter(key, val, replace = false) {
    let _obj = filterValues;
    let f = true;
    _obj.map((item) => {
      if (item.key == key) {
        item.value = replace ? [val] : unCheckInArray(item.value, val);
        f = false;
      }
    });
    if (f) _obj.push({ key: key, value: [val] });

    setFilterValues(_obj);
  }


  let iconsList = imgArr.map((item, index) => {
    let _icon = `c_${index < 10 ? "0" : ""}${index + 1}`;
    return (
      <div
        style={menu.icon == _icon ? { bmenu: "2px solid black" } : {}}
        onClick={() => {
          updateOrderObj("icon", _icon);
        }}
      >
        <UiIconMenu key={index} icon={_icon} size={50} />
      </div>
    );
  });

  let filterItemsList = filterList.map((item, index) => {
    return <UiExpandebleList
      key={index}
      item={item}
      filterValues={filterValues}
      updateFilter={(item, filItem) => {
        updateFilter(item, filItem);
      }}
    />;
  });

  return (
    <div id="page" className="product-add-page">
      <div className="product-add-wrap">
        <div className="product-add-about add">
          <div className="product-add-about-title">
            <div className="product-add-about-title-wrap">
              <h3>Редактировать пункт страниц каталога ( формируют URL )</h3>
            </div>
          </div>
          <div className="product-add-notes">
            <div className="product-add-notes-list-wrap">
              <div className="product-add-info">
                <div className="product-add-info-form">
                  <UiInputText
                    label="Название:"
                    placeholder="Название"
                    value={menu.title}
                    onChange={(val) => {
                      updateOrderObj("title", val);
                    }}
                  />
                </div>

                <div className="product-add-info-form">

                  {menuList && (
                    <UiSearchDropDown
                      label="Родительская категория:"
                      value={inArrayValueByKey(toArrayLabelListKey(menuList, "title"), "value", menu.root_menu_id)}
                      showEmptyVal

                      items={toArrayLabelListKey(menuList, "title")}

                      onChange={(val) => {

                        updateOrderObj(
                          "root_menu_id",
                          val
                        );
                      }}
                    />

                  )}



                </div>




                <div className="product-add-info-form">
                  <UiInputText
                    label="ЧПУ (АВТО ГЕНЕРИРУЕТСЯ):"
                    placeholder="Заголовок"
                    value={menu.title_url}
                    onChange={(val) => {
                      updateOrderObj("title_url", val);
                    }}
                  />
                </div>


                <div className="product-add-about-title">
                  <div className="product-add-about-title-wrap">
                    <h3>SEO настройка (если не указана будет шаблон) </h3>
                  </div>
                </div>



                <div className="product-add-info-form">
                  <UiInputText
                    label="TITLE странциы:"
                    placeholder="title tag"
                    value={menu.seo_title}
                    onChange={(val) => {
                      updateOrderObj("seo_title", val);
                    }}
                  />
                </div>


                <div className="product-add-info-form">
                  <UiInputText
                    label="H1 странциы (заголовок):"
                    placeholder="H1 tag"
                    value={menu.seo_h1}
                    onChange={(val) => {
                      updateOrderObj("seo_h1", val);
                    }}
                  />
                </div>

                <div className="product-add-info-form">
                  <UiInputText
                    label="Описание (на странцие):"
                    placeholder="Описание"
                    value={menu.description}
                    onChange={(val) => {
                      updateOrderObj("description", val);
                    }}
                  />
                </div>


                <div className="product-add-info-form">
                  <UiInputText
                    label="Описание по Маске (на странцие каталога):"
                    placeholder="Описание"
                    value={menu.description_mask}
                    onChange={(val) => {
                      updateOrderObj("description_mask", val);
                    }}
                  />

                  <UiInputText
                    label="Описание по Маске Товара (на странцие товара):"
                    placeholder="Описание"
                    value={menu.description_mask_product}
                    onChange={(val) => {
                      updateOrderObj("description_mask_product", val);
                    }}
                  />

                  <p>
                    SEO настройка (если не указана маска или если есть описание то маска игнорируеться)
                  </p>
                  <p>
                  <br />
                    <b>[category_name]</b> - название категории <br />
                    <b>[product_name]</b> - название продукта <br />
                    <br />
                  </p>

                </div>





                <div className="product-add-about-title">
                  <div className="product-add-about-title-wrap">
                    <h3>Что показывать на странице </h3>
                  </div>
                </div>

                <div className="product-add-info-form">
                  <h3>Показывать товары по категории</h3>
                  <UiDropDown
                    label="Категория товаров:"
                    value={categoryValue}
                    items={categoriesList}
                    sort={true}
                    onChange={(val) => {
                      setCategoryValue(
                        inArrayValueByKey(categoriesList, "title", val)?.id
                      );

                      updateFilter(
                        "category",
                        inArrayValueByKey(categoriesList, "title", val)?.id,
                        true
                      );
                    }}
                  />
                </div>
                <div className="product-add-info-form">
                  <h3>Показывать товары по фильтру</h3>
                  <div>{filterItemsList}</div>
                </div>

                <div className="product-add-info-form">
                  <div className="product-add-info-icon">{iconsList}</div>
                </div>

                <div className="product-add-about-title">
                  <div className="product-add-about-title-wrap">
                    <h3> Тэги для поиска </h3>
                  </div>

                  <UiTableAdaptive
                    titleList={tableTitles}
                    grid={tableTitles.length}
                    tableList={tagsList}
                  />


                  <UiButtonColor
                    color="brand"
                    text={"Создать тэг "}
                    small={true}
                    onClick={() => {
                      navigate(`/menu/tag/add/${id}`);
                    }}
                  />

                </div>



                <div className="product-add-info-form product-add-info-photo">
                  <UiButtonColor
                    color="primary"
                    text={"Сохранит изменения и Закрыть"}
                    small={true}
                    onClick={() => {
                      if (menu.title != "") {
                        let _item = menu;
                        _item.category_filter = convertFilterValue();
                        props.update(_item).then((res) => {
                          if (res) navigate(`/menu`);
                        });
                      } else {
                        alert("Заполните поля название  ");
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ category, menu }) => ({ category, menu }), {
  update: updateAction,
  updateTagAction: updateTagAction,
  getMenuList: getMenuListAction,
  getCategoryList: getCategoryListAction,
  getFilterParams: getFilterParamsAction,
  getTagsList: getTagsListAction
})(MenuEditPage);
